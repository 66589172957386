/* eslint-disable */
import React, { useState } from 'react'
import { Button, Carousel, Image, Modal, Icon, Form, Grid, Loader } from '@patomation/react-ui-components'
import CartButton from '../components/CartButton'
import useGlobal from '../hooks/useGlobal'
import Layout from '../components/Layout'
const TestPage = () => {

console.log('RENDER');

const [globalState, setGlobalState] = useGlobal({counter:24, myVar: 'alright'});

const [complete, setComplete] = useState(false)

return <Layout>
  <Button title='complete' onClick={() =>{
    setComplete(true)
  }}/>
  <Loader
    message='Loading Cart, Please Wait'
    progressBar={true}
    progressBarColor='#86AC41'
    progressBarTime={10000}
    complete={complete}
    spinner={false}
    onComplete={() => {
      console.log('DING');
    }}
    />

</Layout>

return (
<div>
  <h1>My test page</h1><CartButton />
  <Button title='ADDD' onClick={() => {
    const newCounterValue = (globalState.counter || 0) + 1;
    setGlobalState({
      counter: newCounterValue,
      test: 'nice',
      another: 'cool'
     });
  }}/>

  <div>COUNT: {globalState.counter}</div>

  <style>
  {`
    .test-div {
      background: red;
    }
    @media (max-width: 600px) {
      .test-div { background: fuchsia; }
    }
  `}
  </style>
  <div className='test-div'>Nice</div>

  <div style={{
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))'
  }}>
    <div>Item 1</div>
    <div>Item 2</div>
  </div>

  <Grid useBreak={true} col={3}>
    <div style={{color: '#ffffff', background: 'green'}}>Item 1</div>
    <div style={{color: '#ffffff', background: 'purple'}}>Item 2</div>
  </Grid>

  <Form
    fields={[
      {
        name: 'name',
        type: 'text',
        placeholder: 'Name',
        required: true
      },
      {
        name: 'email',
        type: 'email',
        placeholder: 'Email',
        required: true,
        validation: 'email'
      },
      {
        name: 'message',
        type: 'textarea',
        // placeholder: 'Message', //is placeholder is undefined it uses name
        required: true,
        validation: 'length>5' //TODO make this work
      },
    ]} />

  <Icon
    name='star'
    color='purple'
    width='50%'
    />
  <Icon
    name='work'
    color='gold'
    style={{
      width: '20%'
    }}
    />
  <Icon
    name='3d_rotation'
    color='black'
    />

  <Modal show={false}>
  <Carousel>
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776877/samples/bike.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776879/samples/landscapes/architecture-signs.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776880/samples/people/bicycle.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776880/samples/landscapes/beach-boat.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776881/samples/ecommerce/car-interior-design.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776881/samples/ecommerce/leather-bag-gray.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776881/samples/ecommerce/accessories-bag.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776882/samples/imagecon-group.jpg' />
    <Image rectangle src='https://res.cloudinary.com/patomation/image/upload/e_improve,w_1000/v1568776883/samples/food/spices.jpg' />
  </Carousel>
  </Modal>
</div>
)}

export default TestPage
